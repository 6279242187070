import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/global/layout/layout';
import HeadingAndText from '../components/page/heading-and-text/heading-and-text';
import ImageHeader from '../components/page/image-header/image-header';
import { getHeaderImageSlice, getHeadingAndTextSlice } from '../utils/slices';
import Button from '../components/page/button/button';

export const pageQuery = graphql`
  {
    projects: allPrismicProject(filter: {
        uid: {
            ne: "dummy-project"
        },
        data: {
            meta_description: {
                text: {
                    ne: ""
                }
            },
            meta_image: {
                url: {
                    ne: ""
                }
            },
            meta_title: {
                text: {
                    ne: ""
                }
            }
        }
    }) {
    nodes {
      uid
      data {
        meta_title {
          text
        }
        meta_image {
          url
        }
        meta_description {
          html
        }
      }
    }
  }
}
`

export default ({ data }) => (
  <Layout title="Projekte" description="Hier finden Sie eine Auswahl unserer Lieblingsprojekte und Beispiele unserer Arbeit">
    <ImageHeader slice={getHeaderImageSlice('/images/musterhaus-uerzig.jpg')}/>
    <HeadingAndText slice={getHeadingAndTextSlice('Projekte')}/>
    <div className="container my-5">
        <div className="row">
            {data.projects.nodes.length > 0 && data.projects.nodes.map((node, index) => (
                <React.Fragment>
                    <div className="col-12 col-lg-8 mb-3 mb-lg-5">
                        <img className="w-100" src={node.data.meta_image.url} alt={node.data.meta_title.text}></img>
                    </div>
                    <div className="col-12 col-lg-4">
                        <h2 className="mb-3">{node.data.meta_title.text}</h2>
                        <div dangerouslySetInnerHTML={{ __html: node.data.meta_description.html }}></div>
                        <Button
                            text="Projekt ansehen"
                            link={`/projekte/${node.uid}`}
                            color="green" />
                    </div>
                </React.Fragment>
            ))}
        </div>
    </div>
  </Layout>
);
